<template>
    <div>
        <modal :name="modal_name" class="final-modal"
            :class="{'modalZindex':safariTrue,'product-fix-width':loggedInUser.role == 'customer'}"
            transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title text-white" v-if="is_combo">COMBO INFO</span>
                    <span class="v-modal-dialog-title v-modal-title text-white" v-else>PRODUCT INFO</span>
                    <span @click="hide()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-1" style="max-height: 430px;overflow-x: hidden;overflow-y: auto;">
                    <div class="v-modal-layout" style="padding-right: 10px;">
                        <div class="digiblock-section" style="background: transparent;" v-if="showSkeleton">
                            <div class="post">
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sections-div">
                                    <div class="div-content">
                                        <div class="content-left specmeter mr-5">
                                            <div class="line left"></div>
                                        </div>
                                        <div class="content-right">
                                            <span class="right-div">
                                                <div class="line right"></div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="is_combo && !showSkeleton" class="pb-5">
                            <div class="newBox">
                                <div class="bgGrey py-2">
                                    <div class="d-flex justify-content-between">
                                        <div class="col-md-3">
                                            <span
                                                :class="{'imageOrders selectedBackground img-width':productDetails.image_url == ''}">
                                                <avatar class="product-avatar avtar-width"
                                                    v-if="productDetails.image_url == ''"
                                                    :username="productDetails.name.replace(/ .*/,'')"
                                                    :src="productDetails.image_url" :size="40" :rounded="false"
                                                    color="#f5a623" background-color="transparent">
                                                </avatar>
                                                <img :src="productDetails.image_url"
                                                    class="avatar avatar-addons handelBorder" :alt="productDetails.name"
                                                    v-else>
                                            </span>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="d-flex justify-content-between borderBottom pb-0">
                                                <div class="fw-600 col-md-6 p-2">Category</div>
                                                <div class="fw-600 col-md-6 p-2">{{productDetails.category_name}}</div>
                                            </div>
                                            <div class="d-flex justify-content-between borderBottom pb-0">
                                                <div class="fw-600 col-md-6 p-2">Sub Category</div>
                                                <div class="fw-600 col-md-6 p-2">{{productDetails.sub_category_name}}
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between borderBottom pb-0">
                                                <div class="fw-600 col-md-6 p-2">Product Type</div>
                                                <div class="fw-600 col-md-6 p-2">
                                                    {{productDetails.product_type_id || '-'}}</div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div class="fw-600 col-md-6 p-2">Selling Price</div>
                                                <div class="fw-600 col-md-6 p-2">{{productDetails.selling_price}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/product-qr-code.png"
                                                class="qr-style-combo">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="newBox" v-for="(combo,cindex) in productDetails.combo_products" :key="cindex">
                                <div class="infoHeader">
                                    <div class="descriptionheader"
                                        style="background-color:#00448b !important;padding:8px !important;">
                                        <div class="description-text-header-left">
                                            <h5><span class="font-weight-bold fs-16 whiteSpace text-white">Product
                                                    {{cindex + 1}}</span></h5>
                                        </div>
                                        <div class="description-text-header-right">
                                            <span @click="expandProduct(cindex)" class="collapse-addon">
                                                <i v-if="showAlternative.includes(cindex+'new')"
                                                    class="icon-chevron-double-up text-white icon-style"></i>
                                                <i v-else class="icon-chevron-double-down text-white icon-style"></i>
                                            </span>
                                            <!-- <span class="collapse-addon" @click="expandProduct(cindex)">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg" style="width:25px;right: 0px!important;bottom: 8px !important;" v-if="showAlternative.includes(cindex+'new')">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/main-collapse.svg" style="width:25px;right:0px!important;bottom: 8px !important;" v-else>
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showAlternative.includes(cindex+'new')">
                                    <div v-for="(a,ai) in combo.alternatives" :key="ai" class="mt-3 mb-2">
                                        <div class="d-flex justify-content-between"
                                            style="background-color:#ffea92 !important;padding:8px !important;">
                                            <div class="font-weight-bold">Alternate {{ai + 1}}</div>
                                            <div class="font-weight-bold">Quantity {{a.quantity}}</div>
                                        </div>
                                        <div class="d-flex m-3 justify-content-between">
                                            <div class="text-center">
                                                <avatar class="product-avatar" v-if="a.category_id.image_url == ''"
                                                    :username="a.category_id.name.replace(/ .*/,'')"
                                                    :src="a.category_id.image_url" :size="50" :rounded="false"
                                                    color="#f5a623" background-color="#00448b"></avatar>
                                                <img :src="a.category_id.image_url"
                                                    class="avatar avatar-addons handelBorderNew"
                                                    :alt="a.category_id.name" v-else>
                                                <div class="font-weight-bold">Category</div>
                                                <div class="font-weight-bold">{{a.category_id.name}}</div>
                                            </div>
                                            <div class="text-center">
                                                <avatar class="product-avatar" v-if="a.sub_category_id.image_url == ''"
                                                    :username="a.sub_category_id.name.replace(/ .*/,'')"
                                                    :src="a.sub_category_id.image_url" :size="50" :rounded="false"
                                                    color="#f5a623" background-color="#00448b"></avatar>
                                                <img :src="a.sub_category_id.image_url"
                                                    class="avatar avatar-addons handelBorderNew"
                                                    :alt="a.sub_category_id.name" v-else>
                                                <div class="font-weight-bold">Sub Category</div>
                                                <div class="font-weight-bold">{{a.sub_category_id.name}}</div>
                                            </div>
                                            <div class="text-center" v-if="a.hasOwnProperty('product_type_id')">
                                                <avatar class="product-avatar" v-if="a.product_type_id.image_url == ''"
                                                    :username="a.product_type_id.name.replace(/ .*/,'')"
                                                    :src="a.product_type_id.image_url" :size="50" :rounded="false"
                                                    color="#f5a623" background-color="#00448b"></avatar>
                                                <img :src="a.product_type_id.image_url"
                                                    class="avatar avatar-addons handelBorderNew"
                                                    :alt="a.product_type_id.name" v-else>
                                                <div class="font-weight-bold">Product Type</div>
                                                <div class="font-weight-bold">{{a.product_type_id.name}}</div>
                                            </div>
                                            <div class="text-center">
                                                <avatar class="product-avatar" v-if="a.product_id.image == ''"
                                                    :username="a.product_id.name.replace(/ .*/,'')"
                                                    :src="a.product_id.image" :size="50" :rounded="false"
                                                    color="#f5a623" background-color="#00448b"></avatar>
                                                <img :src="a.product_id.image"
                                                    class="avatar avatar-addons handelBorderNew"
                                                    :alt="a.product_id.name" v-else>
                                                <div class="font-weight-bold">Product</div>
                                                <div class="font-weight-bold">{{a.product_id.name}}</div>
                                                <div class="ml-3">
                                                    <div class="font-weight-bold">Description</div>
                                                    <span class="fw-500">{{productDetails.description || '-'}}</span>
                                                </div>
                                            </div>
                                            <div v-if="a.variant_id" class="text-center">
                                                <avatar class="product-avatar" v-if="a.variant_id.image == ''"
                                                    :username="a.variant_id.name.replace(/ .*/,'')"
                                                    :src="a.variant_id.image" :size="50" :rounded="false"
                                                    color="#f5a623" background-color="#00448b">
                                                </avatar>
                                                <img :src="a.variant_id.image"
                                                    class="avatar avatar-addons handelBorderNew"
                                                    :alt="a.variant_id.name" v-else>
                                                <div class="font-weight-bold">Variant</div>
                                                <div class="font-weight-bold">{{a.variant_id.name}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!is_combo && !showSkeleton" class="pb-5">
                            <div class="newBox">
                                <div class="bgGrey">
                                    <div class="d-flex pb-6 justify-content-between borderBottom mt-2 pl-1rem pr-5 pl-2rem">
                                        <div class="d-flex">
                                            <div class="d-flex">
                                                <span
                                                    :class="{'imageOrders selectedBackground':productDetails.image == ''}">
                                                    <avatar class="product-avatar" v-if="productDetails.image == ''"
                                                        :username="productDetails.name.replace(/ .*/,'')"
                                                        :src="productDetails.image" :size="40" :rounded="true"
                                                        color="#f5a623" background-color="transparent">
                                                    </avatar>
                                                    <img :src="productDetails.image"
                                                        class="avatar avatar-addons handelBorder"
                                                        :alt="productDetails.name" v-else>
                                                </span>
                                                <span class="font-weight-bold fs-16 pl-20">
                                                    <h4 class="card-title d-flex mt-0 align-items-center">
                                                        <el-tooltip class="item" effect="dark" content="Non-Veg"
                                                            placement="top"
                                                            v-if="productDetails.product_type_name == 'Non-Veg'">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg">
                                                        </el-tooltip>
                                                        <el-tooltip class="item" effect="dark" content="Veg"
                                                            placement="top"
                                                            v-else-if="productDetails.product_type_name == 'Veg'">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg">
                                                        </el-tooltip>
                                                        <el-tooltip class="item " effect="dark" content="Egg"
                                                            placement="top"
                                                            v-else-if="productDetails.product_type_name == 'Egg'">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg">
                                                        </el-tooltip>
                                                        <el-tooltip class="item" effect="dark" content="Pescatarian"
                                                            placement="top" v-else-if="productDetails.product_type_name == 'Pescatarian'">
                                                            <img style="width:13px;"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg">
                                                        </el-tooltip>
                                                        <!-- <span class="card-title-product-name textCaps" :class="{'ml-2':productDetails.product_type_name != ''}" :title="productDetails.product_name">{{ productDetails.product_name }}</span> -->
                                                        <span
                                                            class="card-title-product-name-width ml-0 fs-16 fw-600">{{ productDetails.name }}</span>
                                                    </h4>
                                                    <span class="fs-14">Descripiton</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/product-qr-code.png"
                                                class="qr-style">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Status</div>
                                            <div class="fw-600 col-md-6 p-2" v-if="productDetails.status">
                                                <button class="btn btn-success btn-smm text-white"
                                                    style="border-radius:6px !important;">Active</button>
                                            </div>
                                            <div class="fw-600 col-md-6 p-2" v-if="!productDetails.status">
                                                <button class="btn btn-danger btn-smm text-white"
                                                    style="border-radius:6px !important;">In Active</button>
                                            </div>
                                        </div>
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Product Type</div>
                                            <div class="fw-600 col-md-6 p-2">{{productDetails.product_type_name || '-'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Category</div>
                                            <div class="fw-600 col-md-6 p-2">{{productDetails.category_name || '-'}}
                                            </div>
                                        </div>
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Brand</div>
                                            <div class="fw-600 col-md-6 p-2">{{productDetails.brand || '-'}}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Sub Category</div>
                                            <div class="fw-600 col-md-6 p-2">{{productDetails.sub_category_name || '-'}}
                                            </div>
                                        </div>
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">No. of Variants<br>
                                                Available</div>
                                            <div class="fw-600 col-md-6 p-2">{{productDetails.no_of_variants || '-'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="newBox mt-3" v-for="(variant,vi) in productDetails.variants" :key="vi">
                                <div class="infoHeader">
                                    <div class="descriptionheader"
                                        style="background-color:#ffea92 !important;padding:8px !important;">
                                        <div class="description-text-header-left">
                                            <h5><span
                                                    class="font-weight-bold fs-16 text-black whiteSpace">{{variant.variant_name}}</span>
                                            </h5>
                                        </div>
                                        <div class="description-text-header-right">
                                            <span class="collapse-addon" @click="expandVariant(vi)">
                                                <i v-if="showVariant.includes(vi+'new')"
                                                    class="icon-chevron-double-up text-black icon-style "></i>
                                                <i v-else class="icon-chevron-double-down text-black icon-style"></i>
                                            </span>
                                            <!-- <span class="collapse-addon" @click="expandVariant(vi)">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/arrow-down.svg"  style="width:25px;right: -2px!important;bottom:8px !important;" v-if="showVariant.includes(vi+'new')">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/main-collapse.svg" style="width:25px;right: -2px!important;bottom:8px !important;" v-else>
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="bgGrey mt-3" v-if="showVariant.includes(vi+'new')">
                                    <div class="d-flex justify-content-between">
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Quantity</div>
                                            <div class="fw-600 col-md-6 p-2">{{variant.variant_quantity || '-'}}</div>
                                        </div>
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Product Code</div>
                                            <div class="fw-600 col-md-6 p-2">{{variant.product_code || '-'}}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Size or Measure</div>
                                            <div class="fw-600 col-md-6 p-2">{{variant.measure || '-'}}</div>
                                        </div>
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Product ID</div>
                                            <div class="fw-600 col-md-6 p-2">{{variant.product_id || '-'}}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Unit Of Measure</div>
                                            <div class="fw-600 col-md-6 p-2">{{variant.uom || '-'}}</div>
                                        </div>
                                        <div class="col-md-6 d-flex justify-content-between borderBottom">
                                            <div class="fw-600 col-md-6 p-2">Stock Keeping Unit</div>
                                            <div class="fw-600 col-md-6 p-2">{{variant.stock_keeping_unit || '-'}}</div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between borderBottom">
                                        <div class="col-md-6 d-flex justify-content-between">
                                            <div class="fw-600 col-md-6 p-2">Selling Price</div>
                                            <div class="fw-600 col-md-6 p-2">{{variant.selling_price || '-'}}</div>
                                        </div>
                                        <div class="col-md-6 d-flex justify-content-between ">
                                        </div>
                                    </div>
                                    <div class="ml-5 fw-600 pt-2">Sale Location</div>
                                    <span v-for="outlet in variant.outlet_id" :key="outlet" class="ml-5 pb-2">
                                        <button class="btn btn-secondary btn-smm text-white mt-3"
                                            style="border-radius:6px !important;">{{outlet}}</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import Avatar from 'vue-avatar'
    import orders from './mixins/orders'
    export default {
        mixins: [orders],
        props: ['modal_name', 'product_id', 'is_combo', 'order_id'],
        data() {
            return {
                safariTrue: false,
                productDetails: {},
                showAlternative: ['0new'],
                showVariant: ['0new'],
                showSkeleton: false,
                isTablet: false
            }
        },
        components: {
            Avatar
        },
        methods: {
            hide() {
                this.$modal.hide(this.modal_name);
                this.$emit('closeProductInfo')
            },
            expandProduct(value) {
                let newValue = value + 'new'
                if (this.showAlternative.find(x => x === newValue)) {
                    this.showAlternative = this.showAlternative.filter(function (x) {
                        if (x !== newValue) {
                            return x
                        }
                    })
                } else {
                    this.showAlternative.push(newValue)
                }
            },
            expandVariant(value) {
                let newValue = value + 'new'
                if (this.showVariant.find(x => x === newValue)) {
                    this.showVariant = this.showVariant.filter(function (x) {
                        if (x !== newValue) {
                            return x
                        }
                    })
                } else {
                    this.showVariant.push(newValue)
                }
            },
            async getDetails() {
                try {
                    this.showSkeleton = true
                    let params = {
                        _id: this.product_id,
                        is_combo: this.is_combo,
                        order_id: this.order_id
                    }
                    let res = await this.getDetailsById(params)
                    this.productDetails = res.response
                    if (!this.is_combo) {
                        if (this.productDetails.image.startsWith("data:image/png;base64")) {
                            this.productDetails.image = ''
                        } else {
                            this.productDetails.image = this.productDetails.image
                        }
                    }
                    this.showSkeleton = false
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            }
        },
        mounted() {
            this.getDetails()
            this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        }
    }

</script>
<style scoped>
    .newBox {
        box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
        background: #fff;
        padding: 10px;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #00448b;
    }

    .fw-600 {
        font-weight: 600;
    }

    .fw-500 {
        font-weight: 500;
    }

    .bgGrey {
        background-color: #eff1f4;
        padding: 5px;
        border-radius: 8px;
    }

    .borderBottom {
        border-bottom: 2px solid #cdcdcd;
        padding-bottom: 5px;
    }

    .selectedBackground {
        box-shadow: 0 1px 6px 0 rgba(28, 141, 255, 0.34);
        background: #00448b !important;
    }

    .img-width {
        width: 100px;
        height: 100px;
    }

    .avtar-width {
        width: 65px !important;
        height: 65px !important;
    }

    .imageOrders {
        background: #fff;
        position: absolute;
        padding: 15px;
        border-radius: 12px;
    }

    .handelBorder {
        width: 70px;
        height: 70px;
        border-radius: 0px;
    }

    .handelBorderNew {
        width: 50px;
        height: 50px;
        border-radius: 0px;
    }

    .post .line.left {
        float: left;
        width: 280px;
        height: 16px;
        border-radius: 7px;
        background-image: linear-gradient(90deg,
                #f4f4f4 0px,
                rgba(229, 229, 229, 0.8) 40px,
                #f4f4f4 80px);
        background-size: 600px;
        animation: shine-lines 2s infinite ease-out;
    }

    .post .line.right {
        width: 280px;
        height: 16px;
        border-radius: 7px;
        background-image: linear-gradient(90deg,
                #f4f4f4 0px,
                rgba(229, 229, 229, 0.8) 40px,
                #f4f4f4 80px);
        background-size: 600px;
        animation: shine-lines 2s infinite ease-out;
    }

    .post .avatar+.line {
        margin-top: 11px;
        width: 100px;
    }

    .post .line~.line {
        background-color: #ddd;
    }

    .final-modal .v-modal-header {
        background-color: #00448b !important;
    }

    .final-modal .v-modal-title {
        color: #fff !important;
    }

    .infoHeader>.descriptionheader .description-text-header-right {
        border-bottom: none !important;
    }

    .infoHeader>.descriptionheader {
        border-radius: 5px !important;
    }

    .qr-style {
        width: 80px;
        height: 80px;
        right: 0px !important;
    }

    .qr-style-combo {
        width: 100px;
        height: 100px;
        right: 0px !important;
    }

    .icon-style {
        bottom: 8px !important;
        font-size: 20px !important;
        float: right;
    }

    @keyframes shine-lines {
        0% {
            background-position: -100px;
        }

        40%,
        100% {
            background-position: 140px;
        }
    }

    @keyframes shine-avatar {
        0% {
            background-position: -32px;
        }

        40%,
        100% {
            background-position: 208px;
        }
    }

    @media (max-width: 530px) {
        .card-title-product-name-width {
            width: 143px !important;
        }
        .pl-1rem{
            padding-left: 0.4rem;
        }
    }
    .pl-2rem{
        padding-left: 2rem;
    }

</style>
